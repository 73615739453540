import React from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";

import PostDetails from "../sections/blog/PostDetails";
import Sidebar from "../sections/blog/BlogPostSidebar";

import Img from "gatsby-image";

const _ = require("lodash");

export const BlogPostTemplate = ({
  data,
  cms = false,
  cmsBodyContent,
  previewImage,
}) => {
  const title = !cms ? data.markdownRemark?.frontmatter?.title : data.title;
  const date = !cms
    ? data.markdownRemark?.frontmatter.date
    : data.date?.toString().substring(0, 15);
  const author = !cms
    ? data.markdownRemark?.frontmatter.author?.join(", ")
    : data.author?.join(", ");

  const tags = !cms ? data.markdownRemark?.frontmatter?.tags : data?.tags;
  const html = !cms ? data.markdownRemark?.html : cmsBodyContent;

  return (
    <>
      <Section className="pb-0">
        <div className="pt-5" />
        <Container>
          <Row className="justify-content-center text-center">
            <Col lg="12">
              <Title variant="hero">{title}</Title>
              <Box className="d-flex justify-content-center">
                <Text mr={3}>{date}</Text>
                <Text mr={3}>
                  {!cms
                    ? tags && (
                        <Link to={`/tags/${_.kebabCase(tags[0])}/`}>
                          {tags[0]}
                        </Link>
                      )
                    : tags && tags[0]}
                </Text>
                <Text>by {author}</Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0">
        <Container>
          <Row>
            <Col lg={!cms ? "8" : "12"} className="mb-5">
              {!cms ? (
                <Img
                  fluid={
                    data.markdownRemark?.frontmatter?.featuredimage
                      .childImageSharp.fluid
                  }
                  alt=""
                  style={{
                    borderRadius: "10px",
                    maxHeight: "500px",
                    marginBottom: "2rem",
                    objectFit: "cover",
                  }}
                />
              ) : (
                previewImage && (
                  <img
                    src={previewImage}
                    alt={title}
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      maxHeight: "500px",
                      marginBottom: "2rem",
                      objectFit: "cover",
                    }}
                  />
                )
              )}
              <PostDetails html={html} tags={tags} cms={cms} />
            </Col>
            {!cms && (
              <Col lg="4" className="">
                <Sidebar
                  currentPostId={data.markdownRemark.frontmatter.postid}
                />
              </Col>
            )}
          </Row>
        </Container>
      </Section>
    </>
  );
};

const BlogDetails = ({ data }) => {
  const { title, description, featuredimage } = data.markdownRemark.frontmatter;
  return (
    <>
      <PageWrapper
        title={title}
        description={description}
        banner={featuredimage.childImageSharp.fluid.src}
        pathname={data.markdownRemark.fields.slug}
        article
      >
        <BlogPostTemplate data={data} />
      </PageWrapper>
    </>
  );
};
export default BlogDetails;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        postid
        description
        tags
        author
        featuredimage {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
