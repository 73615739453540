import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import {
  CardSidebar,
  Block,
  TitleSidebar,
  TitlePost,
  Date,
  CatList,
  CatListItem,
} from "../../components/Sidebar";

const _ = require("lodash");

const BlogPostSidebar = ({ currentPostId }) => {
  const data = useStaticQuery(query);

  const posts = data.posts.nodes;
  const popularPostsIds = data.blogpage.frontmatter.popularPosts.filter(
    (e) => e !== currentPostId
  );

  const popularPosts = posts.filter((item) =>
    popularPostsIds.includes(item.frontmatter.postid)
  );
  const popularPostsLength = popularPosts.length;

  // Tags:
  let tags = [];
  // Iterate through each post, putting all found tags into `tags`
  data.tags.nodes.forEach((edge) => {
    if (_.get(edge, `frontmatter.tags`)) {
      tags = tags.concat(edge.frontmatter.tags);
    }
  });
  // Eliminate duplicate tags
  tags = _.uniq(tags).filter((e) => e);

  return (
    <>
      {popularPostsLength > 0 && (
        <CardSidebar>
          <TitleSidebar>Popular Posts</TitleSidebar>
          {popularPosts.map((item, index) => {
            const styling = index + 1 === popularPostsLength && {
              borderBottom: "none",
              pb: "0",
            };
            return (
              <Block {...styling} key={`${item.frontmatter.title}-${index}`}>
                <TitlePost link={item.fields.slug}>
                  {item.frontmatter.title}
                </TitlePost>
                <Date>{item.frontmatter.date}</Date>
              </Block>
            );
          })}
        </CardSidebar>
      )}

      <CardSidebar>
        <TitleSidebar mb="28px">Categories</TitleSidebar>
        <CatList>
          {tags.map((item) => {
            const countTaggedPosts = posts.filter((post) =>
              post.frontmatter.tags?.includes(item)
            ).length;

            return (
              <CatListItem
                numPosts={countTaggedPosts}
                link={`/tags/${_.kebabCase(item)}/`}
                key={`/tags/${_.kebabCase(item)}/`}
              >
                {item}
              </CatListItem>
            );
          })}
        </CatList>
      </CardSidebar>
    </>
  );
};
export default BlogPostSidebar;

const query = graphql`
  query {
    blogpage: markdownRemark(fileAbsolutePath: { regex: "/blog-page.md/" }) {
      frontmatter {
        popularPosts
      }
    }
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog//" } }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          postid
          tags
          date(formatString: "MMMM Do YYYY")
        }
      }
    }
    tags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog//" } }
    ) {
      nodes {
        frontmatter {
          tags
        }
      }
    }
  }
`;
