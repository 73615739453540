import React from "react";
import styled from "styled-components";

import { Link } from "gatsby";

import { Box, Badge } from "../../components/Core";

import iconQuote from "../../assets/image/png/quote-icon.png";

const _ = require("lodash");

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }

  img {
    border-radius: 10px;
  }

  a {
    color: #4a97d8 !important;

    &:hover {
      color: #c15258 !important;
    }
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);

const PostDetails = ({ html, tags, cms }) => (
  <>
    {/* <!-- Blog section --> */}
    <Post>
      {cms ? (
        <div>{html}</div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: html }} />
      )}
    </Post>
    <Box className="d-flex" mt={4}>
      {tags?.map((tag) => (
        <Link
          to={`/tags/${_.kebabCase(tag)}/`}
          key={`/tags/${_.kebabCase(tag)}/`}
        >
          <BadgePost>{tag}</BadgePost>
        </Link>
      ))}
    </Box>
  </>
);

export default PostDetails;
